<template>
  <div class="inspection-form" :key="renderKey">
    <customer-step-form-component
      :object-data.sync="vehicleData"
      :form-errors="errors"
      :disabled="!canEditVehicle"
    />
    <div class="opacity-0 p-2">
      <Button @click.prevent="() => {}" />
    </div>
    <div class="add-inspection-steps-content">
      <div class="steps-content-footer">
        <div class="mr-auto align-content-center">
          <Button
            class="next"
            :class="{ 'opacity-5 cursor-not-allowed': !canUpdateCustomer }"
            :disabled="!canUpdateCustomer"
            @click="handleSubmit"
          >
            <i class="fas fa-spinner fa-spin mr-1" v-if="isLoading" />
            {{ $t("COMMON.UPDATE") }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import alertLeave from "@/mixins/alert-leave-mixin";
import { cloneDeep } from "lodash";
import swal from "sweetalert2";
import {
  CUSTOMER_TYPE_COMPANY,
  CUSTOMER_TYPE_INDIVIDUAL,
} from "@/constants/customers";
import CustomerStepFormComponent from "@/components/CustomerStepFormComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    CustomerStepFormComponent,
  },

  mixins: [alertLeave],

  props: ["vehicle"],

  data() {
    return {
      vehicleData: this.getVehicleData(),
      isLoading: false,
      errors: [],
      renderKey: 0,
    };
  },

  created() {},

  computed: {
    canEditVehicle() {
      return this.$currentUserCan(this.$permissions.PERM_EDIT_VEHICLES);
    },

    canUpdateCustomer() {
      return (
        this.canEditVehicle &&
        (this.$idExist(this.vehicleData.customer?.id) ||
          this.$idExist(this.vehicleData.approver?.id) ||
          !!this.vehicleData.customer?.organization)
      );
    },
  },

  methods: {
    getVehicleData() {
      if (this.vehicle.customer) {
        return {
          organization: this.vehicle.organization,
          approver: null,
          customer: cloneDeep(this.vehicle.customer),
        };
      }
      if (this.vehicle.mecanic) {
        return {
          organization: this.vehicle.organization,
          approver: cloneDeep(this.vehicle.mecanic),
          customer: null,
        };
      }
      return {
        organization: this.vehicle.organization,
        approver: null,
        customer: null,
      };
    },

    async handleSubmit() {
      if (!this.canEditVehicle) {
        return;
      }
      this.errors = null;
      swal.showLoading();
      try {
        if (!this.$idExist(this.vehicleData.approver?.id)) {
          if (!!this.vehicleData.customer?.organization) {
            await this.handleCustomerFormSubmit(this.vehicleData.customer);
          }
          this.vehicleData.approver = null;
        } else {
          this.vehicleData.customer = null;
        }

        const vehicleData = {
          type: "vehicles",
          id: this.vehicle.id,
          relationshipNames: [
            "organization",
            "allowedLocations",
            "customer",
            "mecanic",
          ],
          mecanic: cloneDeep(this.vehicleData.approver),
          customer: cloneDeep(this.vehicleData.customer),
        };
        await this.$store.dispatch("vehicles/update", vehicleData);

        this.$emit("vehicleUpdated");
        this.renderKey++;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.errors = error.response?.data?.errors;
      } finally {
        swal.close();
      }
    },

    async handleCustomerFormSubmit(customerData) {
      customerData = { ...this.vehicleData.customer, ...customerData };

      if (customerData.customer_type === CUSTOMER_TYPE_INDIVIDUAL) {
        customerData.company_name = "N/A";
        customerData.billing_company_name = "N/A";
      } else if (customerData.customer_type === CUSTOMER_TYPE_COMPANY) {
        customerData.firstname = "N/A";
        customerData.lastname = "N/A";
        customerData.billing_firstname = "N/A";
        customerData.billing_lastname = "N/A";
      }

      console.log(customerData);

      if (this.$idExist(customerData?.id)) {
        await this.$store.dispatch("customers/update", customerData);
      } else {
        delete customerData.id;
        await this.$store.dispatch("customers/add", customerData);
      }
      const customer = await this.$store.getters["customers/customer"];
      this.vehicleData.customer = {
        ...this.vehicleData.customer,
        ...customer,
      };
    },
  },

  watch: {
    vehicle() {
      this.vehicleData = this.getVehicleData();
    },
  },
};
</script>
<style>
.opacity-0 {
  opacity: 0 !important;
}
</style>
