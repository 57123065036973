<template>
  <div class="tab-pane-inner">
    <div class="inspections-list form-inspection">
      <div class="inspection-title">
        <span>
          {{ $objectDenomination(vehicle) }} -
          {{ $t("COMMON.REPAIRS") }}
        </span>
        <ul>
          <li>161 345 km</li>
          <li class="line">|</li>
          <li><copy-element element="MQHD84LF8HU219422" /></li>
        </ul>
      </div>
      <repair-list-table
        :filterVehicle="vehicle.id"
        @onAddRepair="addRepair"
        @onViewRepair="viewRepair"
        @onEditRepair="editRepair"
        @onDeleteRepair="viewRepair"
      />
    </div>
  </div>
</template>

<script>
import {
  QUERY_ACTIONS_ADD,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_VIEW,
} from "@/constants/common";
import RepairListTable from "@/views/Pages/InspectproModule/RepairManagement/partials/RepairListTable.vue";
import CopyElement from "@/components/CopyElement.vue";

export default {
  name: "vehicle-view-repairs",

  components: { RepairListTable, CopyElement },

  props: ["vehicle"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    viewRepair(repair) {
      this.$router.push({
        name: "List Repairs",
        query: { id: repair.id, action: QUERY_ACTIONS_VIEW },
      });
    },
    editRepair(repair) {
      this.$router.push({
        name: "List Repairs",
        query: { id: repair.id, action: QUERY_ACTIONS_EDIT },
      });
    },
    addRepair() {
      this.$router.push({
        name: "List Repairs",
        query: {
          action: QUERY_ACTIONS_ADD,
          vehicle_id: this.vehicle.id,
        },
      });
    },
  },

  mounted() {},

  watch: {
    vehicle(vehicle) {},
  },
};
</script>
