<template>
  <div class="vehicle-images">
    <div class="vehicle-images-item">
      <div class="vehicle-images-item-name">
        {{ $t("VEHICLES.PICTURES") }}
      </div>
      <div class="vehicle-images-item-images">
        <div
          class="vehicle-images-item-images-item"
          v-for="(imageUrl, idx) in vehicle.gallery"
          :key="`vehicle-${vehicle.id}-${idx}`"
        >
          <img :src="imageUrl" alt="logo" />
        </div>
      </div>
    </div>

    <div class="inspection-pictures">
      <div v-if="loading" class="loading"></div>
      <div v-if="!loading" class="inspection-pictures-content">
        <collapse :activeIndex="-1">
          <collapse-item v-for="inspection in inspections" :key="inspection.id">
            <div slot="title" class="collapse-header">
              <div class="collapse-header-left">
                <div class="section-name">
                  <span class="name">
                    {{ inspection.code }}
                  </span>
                  <span class="max-points">
                    {{ inspection.score }}
                  </span>
                </div>
              </div>
              <div class="collapse-header-right">
                <base-button size="sm" @click.stop="showInspection(inspection)">
                  <i
                    class="fa-light fa-arrow-up-right-and-arrow-down-left-from-center"
                  ></i>
                </base-button>
              </div>
            </div>
            <div>
              <div class="field-images">
                <div
                  v-for="(images, name) in inspection.images"
                  :key="name"
                  class="field-images-item"
                >
                  <div class="field-images-item-name">
                    {{ name }}
                  </div>
                  <div class="field-images-item-images">
                    <div
                      class="field-images-item-images-item"
                      v-for="(imageUrl, idx) in images"
                      :key="`${name}-${idx}`"
                    >
                      <img :src="imageUrl" alt="logo" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </collapse-item>
        </collapse>
      </div>
    </div>
  </div>
</template>

<script>
import { QUERY_ACTIONS_VIEW } from "@/constants/common";
import Collapse from "@/components/Collapse/Collapse";
import CollapseItem from "@/components/Collapse/CollapseItem";

export default {
  name: "vehicle-view-inspections",

  components: { [Collapse.name]: Collapse, [CollapseItem.name]: CollapseItem },

  props: ["vehicle"],

  data() {
    return {
      loading: false,
      inspections: [],
    };
  },

  computed: {},

  created() {},

  methods: {
    async getVehicleInspectionsPictures() {
      let params = {
        sort: "-updated_at",
        filter: {
          vehicle: this.vehicle.id,
        },
        page: {
          number: 1,
          size: 100,
        },
        include: "",
      };
      await this.$store.dispatch("inspections/list", params);
      this.inspections = this.$store.getters["inspections/list"];
    },

    async showInspection(inspection) {
      this.$router.push({
        name: "List Inspections",
        query: { id: inspection.id, action: QUERY_ACTIONS_VIEW },
      });
    },
  },

  mounted() {
    this.getVehicleInspectionsPictures();
  },

  watch: {
    vehicle(vehicle) {
      this.getVehicleInspectionsPictures();
    },
  },
};
</script>
