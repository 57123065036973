<template>
  <div class="tab-pane-inner">
    <div class="inspections-list form-inspection">
      <div class="inspection-title">
        <span>
          {{ $objectDenomination(vehicle) }} -
          {{ $t("COMMON.REQUESTS") }}
        </span>
      </div>
      <request-list-table
        :filterVehicle="vehicle.id"
        @onAddRequest="addRequest"
        @onViewRequest="viewRequest"
        @onEditRequest="editRequest"
        @onDeleteRequest="viewRequest"
      />
    </div>
  </div>
</template>

<script>
import {
  QUERY_ACTIONS_ADD,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_VIEW,
} from "@/constants/common";
import RequestListTable from "../../RequestManagement/partials/RequestListTable.vue";

export default {
  name: "vehicle-view-requests",

  components: { RequestListTable },

  props: ["vehicle"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    viewRequest(request) {
      this.$router.push({
        name: "List Requests",
        query: { id: request.id, action: QUERY_ACTIONS_VIEW },
      });
    },
    editRequest(request) {
      this.$router.push({
        name: "List Requests",
        query: { id: request.id, action: QUERY_ACTIONS_EDIT },
      });
    },
    addRequest() {
      this.$router.push({
        name: "List Requests",
        query: {
          action: QUERY_ACTIONS_ADD,
          vehicle_vin: this.vehicle.vin,
          vehicle_id: this.vehicle.id,
        },
      });
    },
  },

  mounted() {},

  watch: {
    vehicle(vehicle) {},
  },
};
</script>
